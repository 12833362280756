@use 'settings';

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute;
  color: settings.$text-color;
  transform-origin: left 0;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ng-select .ng-select-container:after {
  border-bottom: 1px solid settings.$dark-border-color !important;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: block;
  content: '';
  transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
}

.ng-select {
  width: 100%;

  .ng-arrow-wrapper {
    align-self: flex-end;
  }

  &.ng-select-single .ng-select-container .ng-arrow-wrapper {
    bottom: 11px;
  }

  .ng-arrow-wrapper .ng-arrow {
    color: settings.$text-color;
    border-top: 4px solid;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }

  .ng-value-container {
    align-items: stretch;
    padding: 0.4375em 0;
    border-top: 0.84375em solid transparent;

    .ng-placeholder {
      color: settings.$medium-text-color;
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      color: settings.$medium-text-color;

      &:after {
        border-width: 1px;
        border-color: var(--primary-color);
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          color: settings.$text-color;
        }
      }

      .ng-value-container {
        .ng-placeholder {
          color: settings.$text-color;
        }
      }
    }
  }

  .ng-select-container {
    min-height: 49px;
    color: settings.$medium-text-color;

    .ng-value-container {
      .ng-placeholder {
        color: settings.$medium-text-color;
        font-weight: 400;
      }

      .ng-input > input {
        transform: translateY(-8px);
      }
    }
  }

  .ng-select-container.ng-has-value .ng-value-container .ng-placeholder {
    color: settings.$text-color !important;
  }

  &.ng-select-opened .ng-select-container {
    z-index: 1 !important;
  }
}

.ng-dropdown-panel {
  &.ng-select-bottom {
    background: settings.$white;
    border: 1px solid settings.$light-text-color;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
    z-index: 3;
    border-radius: 0 0 4px 4px;
  }

  &.ng-select-top {
    background: settings.$white;
    border: 1px solid settings.$light-text-color;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
    z-index: 3;
    border-radius: 4px 4px 0 0;
  }

  .ng-dropdown-panel-items {
    padding: 8px 0;

    .ng-option {
      position: relative;
      line-height: 36px;
      min-height: 36px;
      padding: 0 24px;

      &:after {
        content: '';
        background: red;
        width: 100%;
        background: color(--primary-color);
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: -1;
        opacity: 0;
        transition: opacity 250ms;
      }

      &.ng-option-selected {
        background: transparent;
        color: settings.$text-color;
      }

      &.ng-option-marked {
        background: transparent;

        &:after {
          opacity: 0.12;
        }
      }
    }
  }
}
