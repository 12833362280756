@use 'settings';

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import 'ng-select';

:root {
  --primary-color: #2991ff;
  --primary-color-semi: #2991ff12;
  --accent-color: #626262;
  --accent-color-hover: #626262bf;
  --accent-color-semi: #62626212;
  --branding-logo: url('/assets/binsend-logo-horizontal-white@3x.png');
}

html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  font-family: 'Lato', sans-serif;
}

pre {
  font-family: 'Lato', sans-serif;
}

a {
  color: settings.$text-color;
  font-weight: bold;
  text-decoration: none;

  .material-icons {
    font-size: 20px;
  }
}

.hidden {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-grow: 1;
  color: settings.$text-color;
  overflow-x: hidden;
  position: relative;

  &:before {
    z-index: -1;
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    height: 450px;
    background: linear-gradient(180deg, #ffffff 10%, #f4f6f8 100%);
  }

  &:after {
    z-index: -2;
    pointer-events: none;
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #f4f6f8;
  }
}

.info-tooltip {
  font-size: 14px;
  color: settings.$text-color;
}

.btn-base {
  outline: none;
  border-radius: 5px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.22px;
  padding: 12px 14px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  user-select: none;

  svg,
  img {
    padding-right: 8px;
    width: 16px;

    path {
      fill: currentcolor;
    }
  }

  .material-icons,
  .material-icons-outlined {
    padding-right: 8px;
    font-size: 18px;
  }

  span {
    display: flex;
    align-items: baseline;
  }
}

.btn-primary {
  @extend .btn-base;
  background-color: var(--accent-color);
  font-weight: bold;
  color: settings.$white;
  border: 0;

  &:hover {
    background-color: var(--accent-color-hover);
  }

  &:disabled {
    background-color: var(--accent-color-semi);
    border: solid 0 settings.$border-color;
    cursor: not-allowed;
    box-shadow: none;
  }

  &--large {
    height: 50px;
    font-size: 16px;
    padding: 12px 14px;
  }
}

.btn-secondary-large {
  @extend .btn-base;
  background-color: settings.$white;
  font-weight: bold;
  color: settings.$text-color;
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

  span {
    font-size: 20px;
    margin-right: 16px;
  }

  &:enabled:hover {
    background-color: settings.$hover-background-color;
  }

  &:disabled {
    cursor: not-allowed;
    border: solid 0 settings.$border-color;
    box-shadow: none;
    opacity: 0.5;
  }
}

.btn-tertiary {
  @extend .btn-base;
  display: flex;
  border: 0;
  color: settings.$text-color !important;
  background-color: settings.$secondary-background-color;

  &:enabled:hover {
    background-color: settings.$row-hover-color;
  }
}

.btn-secondary {
  @extend .btn-base;
  background-color: settings.$white;
  font-weight: bold;
  color: settings.$text-color;
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

  &:enabled:hover {
    background-color: settings.$row-hover-color;
  }

  &:disabled {
    color: settings.$light-text-color;
    cursor: not-allowed;
    border-color: var(--accent-color-semi);
    box-shadow: none;
  }
}

h1 {
  font-size: 22px;
}

h1,
h2 {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: settings.$text-color;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.27px;
  color: var(--accent-color);
}

.progress-bar {
  background: settings.$hover-background-color;
  border: 0;
  transition: width 0.5s;
  height: 100%;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.content-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.sub-heading {
  font-size: 18px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .material-icons {
    margin-right: 4px;

    &.active-icon {
      color: var(--accent-color);
    }

    &:hover {
      cursor: pointer;
    }
  }

  img {
    margin-right: 16px;
  }

  .material-icons-outlined {
    margin-right: 16px;
  }

  .material-icons {
    margin-right: 16px;
  }
}

.container {
  display: flex;
}

input[type='password'],
input[type='date'],
input[type='number'],
input[type='tel'],
textarea,
select,
input[type='text'] {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px;
  border-style: solid;
  padding: 0 0 3px 0;
  border-color: settings.$dark-border-color;
  font-size: 15px;
  color: settings.$medium-text-color;
  letter-spacing: 0.27px;
  font-family: 'Lato', sans-serif;
  background-color: settings.$white;
  margin: 8px 0 24px 0;
  min-width: 50px;

  &:focus {
    outline: none;
    border-bottom: 1px solid settings.$text-color;
  }

  &:disabled,
  &[ng-reflect-is-disabled='disabled'] {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
}

ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  .tooltip-caret.position-top {
    display: none;
  }

  border: 1px solid settings.$border-color;
  background-color: settings.$white;
  color: settings.$text-color;
  border-radius: 4px;
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='tel'],
input[type='text'],
select {
  padding: 7px 0;
  //min-width: 250px;
}

textarea {
  font-family: inherit;
}

input[type='text']::placeholder,
textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: settings.$light-text-color;
  opacity: 1; /* Firefox */
}

input[type='text']:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: settings.$light-text-color;
}

input[type='text']::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: settings.$light-text-color;
}

select:focus,
input:focus,
textarea:focus {
  outline: none;
  border-color: var(--accent-color);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.14);
  margin: 1em 0;
  padding: 0;
}

.popup.share-form {
  z-index: 4;
  margin-right: 50%;
  top: calc(50% - 350px);
  right: -315px;
  padding: 32px;
  min-width: 630px;
  position: absolute;
}

.active-link {
  color: var(--accent-color);
}

.menu {
  font-size: 14px;

  button {
    border: none;
    padding: 8px 16px;
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    text-align: left;
    color: settings.$text-color;
    font-weight: bold;
    display: block;
    text-decoration: none;
  }

  button:hover {
    background-color: settings.$hover-background-color;
  }

  a {
    text-align: left;
    font-weight: bold;
    display: block;
    padding: 8px 24px;
    text-decoration: none;
  }

  a:hover {
    background-color: settings.$hover-background-color;
  }
}

.popup {
  //overflow: hidden;
  border: solid 1px settings.$menu-border-color;
  top: 45px;
  float: right;
  padding: 4px 0;
  background: settings.$white;
  border-radius: 3px;
  position: absolute;
  z-index: 3;
}

.popout-menu {
  right: -64px;
  width: 175px;
  max-height: 0;
  transition: max-height 0.5s, opacity 0.5s;
  transform: scale(0, 0);
  transform-origin: 50% 0;
  background: settings.$secondary-background-color;
  border-color: settings.$menu-border-color;
  margin-top: 5px;
  padding: 0;
  opacity: 0;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(240, 243, 244, 0);
    border-bottom-color: #f0f3f4;
    border-width: 15px;
    margin-left: -15px;
  }

  &:before {
    border-color: rgba(215, 222, 225, 0);
    border-bottom-color: #d7dee1;
    border-width: 16px;
    margin-left: -16px;
  }

  button {
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 24px;
    flex-direction: row;
    align-content: center;
    justify-items: center;
  }

  a {
    display: flex;
    align-items: center;
    height: 24px;
    line-height: 24px;
    flex-direction: row;
    align-content: center;
    justify-items: center;
  }
}

ul.menu {
  list-style-type: none;
  margin: 0;
  min-width: 150px;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  padding: 0;

  li {
    display: flex;
    font-weight: normal;
    align-content: stretch;
    flex-direction: column;

    a {
      font-weight: 400;
      padding: 8px 24px;
      font-size: 14px;

      .material-icons {
        font-size: 16px;
      }

      .material-icons-outlined {
        font-size: 16px;
      }

      img {
        height: 16px;
        width: 16px;
      }
    }

    button {
      font-weight: inherit;
      padding: 8px 24px;
      font-size: 14px;

      span {
        font-size: 16px;
      }

      img {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.remove-button {
  color: settings.$delete-color !important;
  cursor: pointer;
}

.click:focus + .popup,
.click + .popup:hover {
  max-height: 700px;
  -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04),
    0 2px 4px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  overflow: unset;
  opacity: 1;
  transform: scale(1);
  transition: max-height 0.2s, opacity 0.2s, transform 0.2s;
}

.circle {
  width: 4px;
  height: 4px;
  margin: 3px;
  background: settings.$medium-text-color;
  border-radius: 50%;
  display: block;
}

.menu-button {
  font-size: 30px;
  color: settings.$medium-text-color;
  margin-left: 16px;
  cursor: pointer;
  z-index: 2;
  display: flex;
  border-radius: 4px;
  padding: 20px 0;

  &:focus {
    outline: none;
  }
}

.material-icon-primary {
  color: var(--primary-color);
}

.material-icon-light {
  color: settings.$white;
}

.material-icon-approved {
  color: settings.$approve-color;
}

.material-icon-pending {
  color: settings.$pending-color;
}

.material-icon-rejected {
  color: settings.$rejected-color;
}

.material-icon-received {
  color: settings.$received-color;
}

.material-icon-non-applicable {
  color: settings.$non-applicable-color;
}

.material-icon-text {
  color: settings.$text-color;
}

tr {
  .material-icons {
    font-size: 20px;
  }

  .material-icons-outlined {
    font-size: 20px;
  }
}

.menu > li > button {
  display: flex;
  font-size: 14px;
  align-content: center;

  img {
    margin-right: 24px;
  }

  span {
    margin-right: 24px;
  }
}

.menu > li > a {
  display: flex;
  font-size: 14px;
  align-content: center;

  img {
    margin-right: 24px;
  }

  .material-icons {
    margin-right: 24px;
  }

  .material-icons-outlined {
    margin-right: 24px;
  }
}

button {
  cursor: pointer;
}

.input-group {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: baseline;
  padding: 0 16px;

  .invalid-input-column {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 2px;
    }

    small {
      padding-bottom: 4px;
      color: settings.$delete-color;
    }
  }

  label {
    width: 158px;
    text-align: right;
    margin-right: 16px;
    font-size: 12px;
  }
}

label {
  cursor: inherit;
}

/* Customize the label (the container) */
.checkboxcontainer {
  position: relative;
  padding-left: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  user-select: none;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkboxcontainer input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border: solid 1px settings.$medium-background-color;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: settings.$white;
}

/* On mouse-over, add a grey background color */
.checkboxcontainer:hover input:enabled ~ .checkmark {
  border: solid 1px settings.$text-color;
  cursor: pointer;
}

.checkboxcontainer:hover input:not(:disabled):checked ~ .checkmark {
  background-color: var(--accent-color-hover);
  cursor: pointer;
}

/* When the checkbox is checked, add a blue background */
.checkboxcontainer input:checked ~ .checkmark {
  background-color: var(--accent-color);
  border: solid 1px var(--accent-color);
}

.checkboxcontainer:hover input:checked ~ .checkmark {
  border: solid 1px var(--accent-color);
}

.checkboxcontainer input:checked:disabled ~ .checkmark {
  background-color: var(--accent-color-hover);
}

.checkboxcontainer input:disabled ~ .checkmark {
  cursor: not-allowed;
}

.checkboxcontainer.disabled {
  color: settings.$medium-text-color;
  cursor: not-allowed;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxcontainer .checkmark:after {
  left: 3px;
  top: 1px;
  width: 2px;
  height: 5px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input.searchBox {
  background: url(~assets/search.svg) no-repeat scroll 0 7px;
  background-size: 20px 20px;
  padding-left: 30px;
}

// TABLES
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  th,
  td {
    padding: 15px 10px;
  }

  tr {
    border-bottom: 1px solid settings.$medium-background-color;
  }

  th {
    &[ng-reflect-app-sort] {
      cursor: pointer;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &.sortable {
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: settings.$row-hover-color;
      }
    }

    app-sort-icon {
      display: inline-table;
      height: 12px;
      width: 12px;
      margin-left: 13px;
      visibility: hidden;
      opacity: 0;
      transition: opacity 200ms, visibility 200ms;
    }

    &[data-order='asc'],
    &[data-order='desc'] {
      color: var(--accent-color);

      app-sort-icon {
        visibility: visible;
        opacity: 1;
      }
    }

    &[data-order='desc'] {
      app-sort-icon {
        transform: rotateX(180deg);
      }
    }

    text-align: left;
    font-size: 14px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      width: 196px;

      &.strech {
        width: unset;
      }
    }

    &.icon-column {
      text-align: center;
      width: 70px;
    }

    &:last-of-type {
      width: 50px;
      text-align: right;
    }
  }

  .table-row:hover {
    background-color: settings.$row-hover-color;

    &:not(.non-clickable) {
      cursor: pointer;
    }
  }

  td {
    font-size: 14px;
    color: settings.$text-color;

    button {
      background-color: transparent;
      border: 0;
      color: settings.$text-color;
      padding: 0;
      margin-left: 28px;
    }

    &.icon-column {
      text-align: center;
    }
  }
}

.tooltip-container {
  box-sizing: content-box;
  margin: -5px;
  padding: 5px;
  z-index: 200;
  position: fixed;
  border-radius: 4px;
  background-color: settings.$dark-background-color;
  color: settings.$white;
  font-size: 13px;
  transform: translate(-50%, -100%);
  transform-origin: center bottom;
  white-space: nowrap;

  &.large-version {
    margin: 0px;
    padding: 10px;
    max-width: 450px;
    white-space: initial;
  }
}

.drop-container {
  padding: 5px;
  position: fixed;
  z-index: 100;
  border-radius: 4px;
  background-color: settings.$dark-background-color;
  color: red;
  font-size: 13px;
  transform: translate(-50%, -100%);
  white-space: nowrap;
}

.non-clickable {
  cursor: default;
}

.empty-item-image {
  object-fit: contain;
}

.empty-item-container {
  width: inherit;
  display: flex;
  flex-grow: 1;
  margin: 20px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  label,
  .menu-button,
  button {
    margin: 20px 0;

    span {
      display: flex;
      align-items: flex-end;
    }
  }

  .btn-with-submenu .popout-menu {
    top: 72px;
  }

  .no-items-title {
    margin: 0 0 10px 0;
  }

  .no-items-subtitle {
    margin: 0 0 15px 0;
  }
}

.settings-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
}

.workspace-settings-group {
  margin: 0 48px 10px 0;
  flex-grow: 1;
  width: calc(33% - 45px);
  min-width: 341px;

  .settings-title {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;

    &.padding-left {
      padding-left: 16px;
    }
  }

  &.setting-buttons {
    flex-grow: 0.5;
  }

  .with-explanation {
    margin-bottom: 0;
  }

  .workspace-settings-explanation {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 14px;
  }

  .workspace-settings-object {
    border-radius: 4px;
    border: solid 1px settings.$border-color;
    background: settings.$white;
    padding: 16px;
    margin-bottom: 8px;
    position: relative;

    .input-group {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: baseline;

      input {
        width: 100%;
      }

      .date-input {
        border-radius: 4px;
        border: solid 1px #dae1e3;
        padding: 13px 16px;
      }

      label {
        text-align: left;
        width: auto;

        &.checkbox-title {
          padding-left: 16px;
          margin: 0 !important;
        }

        &.checkbox-subtitle {
          padding-left: 38px;
        }
      }

      input[type='date'] {
        margin: 0;
        border: 0;
        padding: 0;
      }

      &.padding-bottom {
        padding-bottom: 16px;
      }
    }
  }
}

@media screen and (max-width: settings.$tablet) {
  .workspace-settings-group {
    width: 100%;
    flex-grow: 1;
    min-width: unset;
  }
}

@media screen and (max-width: settings.$mobile) {
  input[type='text'],
  select {
    min-width: unset;
    width: 100%;
  }

  .workspace-settings-group {
    margin: 0;
  }
}

.btn-with-submenu {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 2px 0;

  .btn-primary {
    margin-right: 0;
    border-radius: 5px 0 0 5px;
  }

  .popout-menu {
    top: 40px;
    width: 310px;
    right: -273px;
    transform-origin: 0 0;

    ul.menu li button span {
      font-size: 24px;
    }

    ul.menu li a .material-icons-outlined {
      font-size: 24px;
    }

    ul.menu li a .material-icons {
      font-size: 24px;
    }

    ul.menu li a .material-icons-outlined {
      font-size: 24px;
    }

    ul.menu li a img {
      height: 24px;
      width: 24px;
    }

    &:after,
    &:before {
      content: none;
    }
  }

  .disabled-menu-button {
    background-color: var(--accent-color-semi);
    padding: 0 10px;
    z-index: 2;
    display: flex;
    border: none;
    border-left: 1px solid settings.$white;
    margin-left: 0;
    border-radius: 0 5px 5px 0;
    box-shadow: none;

    img {
      padding-right: 0 !important;
    }

    .material-icons-outlined {
      padding-right: 0 !important;
    }

    .material-icons {
      padding-right: 0 !important;
    }

    &:hover {
      cursor: not-allowed;
    }
  }

  .menu-button {
    padding: 0 10px;
    z-index: 2;
    display: flex;
    border-left: 1px solid settings.$white;
    margin-left: 0;
    border-radius: 0 5px 5px 0;

    img {
      padding-right: 0 !important;
    }

    .material-icons-outlined {
      padding-right: 0 !important;
    }

    .material-icons {
      padding-right: 0 !important;
    }

    &:hover {
      background-color: var(--accent-color-hover);
    }
  }
}

.invalid-regex {
  display: flex;
  align-items: start !important;
  flex-direction: column;

  .no-bottom-margin {
    margin-bottom: 0;
  }

  .text-danger {
    font-size: 11px;
    color: settings.$delete-color;
    margin-bottom: 10px;
  }
}

.pending {
  color: settings.$pending-color;
}

.approved {
  color: settings.$approve-color;
}

.received {
  color: settings.$received-color;
}

.rejected {
  color: settings.$rejected-color;
}

.non-applicable {
  color: settings.$non-applicable-color;
}

/* New version remove "-new" once the new styling is getting used and remove the old version */
.checkboxcontainer--new {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  min-height: 18px;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;

    &:disabled ~ .checkmark--new {
      cursor: not-allowed;
    }
  }

  .checkmark--new {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
    background: settings.$white;
    border: solid 1px settings.$medium-background-color;
    transition: background 300ms;

    &:after {
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      left: 5px;
      top: 1px;
      width: 4px;
      height: 10px;
      border: solid var(--primary-color);
      border-width: 0 2px 2px 0;
      transition: opacity 250ms, visibility 250ms, transform 200ms;
      opacity: 0;
      visibility: hidden;
      transform: rotate(45deg) scale(0.85);
    }
  }

  &:hover input ~ .checkmark--new,
  input:checked ~ .checkmark--new {
    background: settings.$grey-background-color;
  }

  input:checked ~ .checkmark--new:after {
    opacity: 1;
    visibility: visible;
    transform: rotate(45deg) scale(1);
  }

  .checkmark:after {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    left: 6px;
    top: 1px;
    width: 4px;
    height: 10px;
    border: solid var(--primary-color);
    border-width: 0 2px 2px 0;
    transition: opacity 250ms, visibility 250ms, transform 200ms;
    opacity: 0;
    visibility: hidden;
    transform: rotate(45deg) scale(0.85);
  }

  &.themed {
    .checkmark--new {
      border: 1px solod var(--primary-color);
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    color: settings.$medium-text-color;

    &:hover input ~ .checkmark--new {
      background: settings.$white;
    }
  }
}

.radiocontainer {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  min-height: 18px;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;

    &:disabled ~ .radiomark {
      cursor: not-allowed;
    }
  }

  .radiomark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 9px;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
    background: settings.$white;
    border: solid 1px settings.$medium-background-color;
    transition: background 300ms;

    &:after {
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      left: 5px;
      top: 5px;
      width: 6px;
      height: 6px;
      border-radius: 4px;
      background: var(--primary-color);
      transition: opacity 250ms, visibility 250ms, transform 200ms;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.1);
    }
  }

  &:hover input ~ .radiomark,
  input:checked ~ .radiomark {
    background: settings.$grey-background-color;
  }

  input:checked ~ .radiomark:after {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: settings.$medium-text-color;

    &:hover input ~ .radiomark {
      background: settings.$white;
    }
  }
}

.checkbox-switch {
  position: relative;
  padding: 0 16px 0 10px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .switch {
    position: absolute;
    top: 50%;
    right: 0;
    height: 12px;
    width: 22px;
    border-radius: 6px;
    box-sizing: border-box;
    background: transparent;
    border: solid 2px settings.$text-color;
    transition: border-color 300ms;
    transform: translateY(-50%);

    &:after {
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      left: 1px;
      top: 1px;
      width: 6px;
      height: 6px;
      background: settings.$text-color;
      border-radius: 50%;
      transition: transform 200ms;
      transform: translateX(0) scale(1);
    }
  }

  input:checked ~ .switch {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }

  input:checked ~ .switch:after {
    transform: translateX(10px) scale(1.1);
    background: settings.$white;
  }
}

label.lightswitch {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  font-size: 15px;

  input {
    display: none;
    appearance: none;
  }

  i {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    margin-right: 12px;
    background-color: settings.$border-color;
    border-radius: 22px;
    vertical-align: text-bottom;
    transition: background-color 300ms linear;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 20px;
    }

    &::before {
      width: 40px;
      background-color: settings.$border-color;
      border-radius: 10px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 250ms linear;
    }

    &::after {
      width: 20px;
      background-color: settings.$white;
      border-radius: 50%;
      transform: translate3d(2px, 2px, 0);
      transition: all 200ms ease-in-out;
    }
  }

  &:active {
    i::after {
      width: 28px;
      transform: translate3d(2px, 2px, 0);
    }

    input:checked + i::after {
      transform: translate3d(16px, 2px, 0);
    }
  }

  input:checked + i {
    background-color: var(--primary-color);
  }

  input:checked + i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  }

  input:checked + i::after {
    transform: translate3d(22px, 2px, 0);
  }
}

.coupled-item:not(.cdk-drag-preview):not(.cdk-drag-placeholder) {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    display: block;
    pointer-events: none;
    left: -1px;
    top: 0;
    height: 100%;
    width: 3px;
    background: var(--primary-color);
  }
}

section.coupled-item:not(.cdk-drag-preview):not(.cdk-drag-placeholder) {
  position: relative;

  &:after {
    display: none;
  }

  .workspace-section__top {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      display: block;
      pointer-events: none;
      left: -1px;
      top: 5px;
      height: calc(100% - 10px);
      width: 3px;
      background: var(--primary-color);
    }
  }
}
