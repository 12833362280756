//colors
$dark-text-color: #5f6368;
$text-color: #404040;
$medium-text-color: #999999;
$light-text-color: #e0e0e0;
$secondary-background-color: #efefef;
$hover-background-color: #f7f7f7;
$popup-background-color: rgba(240, 243, 244, 0.82);
$popup-background-color-light: rgba(218, 221, 226, 0.82);
$medium-background-color: #dae1e3;
$dark-background-color: #555555;
$white: #ffffff;
$border-color: #e3e3e3;
$dark-border-color: #5f6368;
$alternate-row-color: #fbfbfb;
$grey-background-color: #f2f2f2;
$row-hover-color: #f5f5f5;
$menu-border-color: #d7dee1;
$menu-list-color: #898989;
$delete-color: #ce1313;
$danger-color: #e31f0d;
$approve-color: #3be087;
$pending-color: #c3c3c3;
$received-color: #ffc674;
$rejected-color: #e75449;
$non-applicable-color: #4fccff;
$warning-border-color: #ffbcb5;
$warning-fill-color: #ffeae8;
$warning-hover-color: #f5e1df;
$blue-checkbox-color: #2991ff;
$red-highlight-color: rgba(213, 0, 69, 0.07);
$boxShadow: 0 2px 15px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.04);

//breakpoints
$large-desktop: '1600px';
$desktop: '1400px';
$laptop: '1280px';
$tablet: '1024px';
$small-tablet: '868px';
$mobile: '628px';
$small-mobile: '424px';
